@import '../../theme.scss';

.root {
    flex: 1;
    display: flex;
    max-width: 1000px;
    justify-content: center;
    margin: 0 auto;
    flex-wrap: wrap;

    @include mobile-only {
        flex-direction: column;
    }

    .column {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin: 0 2.5vw;
        margin-top: 10vh;
        min-width: 300px;
        max-width: 400px;

      &.hidden {
        display: none;
      }
    }
}

.responsiveTextField {
    width: auto;
    flex-basis: 200px;
}

.privacyDisplay {
    flex: 1;
    @include mobile-only {
        min-height: 100px;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    .privacyInput {
        width: 100%;
        border: 0;
        font-size: 50px;
        font-weight: 700;
        height: 1em;
        padding: $spacing-small;
        margin: 0;
        background: transparent;
        box-sizing: content-box;
        text-align: center;
        overflow: hidden;
        text-transform: uppercase;
        width: 100%;
    }
}

.patientIdRow {
    display: flex;
    align-items: flex-start;
    & > :first-child {
        flex: 1;
    }
    .spinner {
        margin-left: $spacing-small;
        // ugly, hard-coded tweak based on observation:
        margin-top: 24px;
    }
}

.nameRow,
.ageGenderRow,
.heightWeightRow {
    display: flex;
    & > * {
        flex: 1;
    }
}

.formItem {
    margin: $spacing-medium 0;
}

.initialComplaint {
  display: inline-block;
  margin-right: $spacing-small;
}

.result,
.patientExistsMsg {
  text-align: center;
  color: $primary-color;
}

.result {
  margin: $spacing-medium 0;

  &.error {
    color: $error;
  }
}

