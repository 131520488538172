@import '../../../theme.scss';

.table {
    border-collapse: collapse;
}

.tableBody {

}

.tableHead {

}

.tableRow {

}

@mixin cell() {
}

.tableHeader {
    @include cell();
    color: $grey;
    text-transform: uppercase;
    font-weight: bold;
    font-size: .75rem;
    text-align: left;
    padding: 1em $spacing-small;
}

.tableCell {
    @include cell();
    border: 1px solid $ultralight-grey;
    border-right: none;
    padding: 2*$spacing-small $spacing-small;
    &:first-child {
        border-left: none;
    }
}