@mixin flex-column {
    display: flex;
    flex-direction: column;
}

@mixin flex-row {
    display: flex;
    flex-direction: row;
}

@mixin screen-root {
    min-height: 100vh;
}