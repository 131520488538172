@import '../../theme.scss';

@mixin inline-spacing {
    margin: 0 $spacing-small $spacing-small;

    &:first-child {
        margin-left: 0;
    }
    &:last-child {
        margin-right: 0;
    }
}

@mixin inputDefaults {
    @include inline-spacing();
    display: inline-block;
    vertical-align: bottom;

    &.fullWidth {
        width: 100%;
        margin: 0 0 $spacing-small;
    }
    &.disabled {
        opacity: .7;
        &, * {
            cursor: not-allowed;
            background: none;
        }
    }

    label {
        width: 100%;
        &>* {
            display: block;
        }
    }
}