@import '../../../../theme.scss';

.root {
    margin: $spacing-medium;
}

.navItem {
    display: inline-block;
    padding: $spacing-small 2*$spacing-small;
    color: inherit;
    text-decoration: none;
    &.selected {
        color: $primary-color;
        font-weight: bold;
    }
    &.disabled {
        color: $primary-text-color;
    }
}