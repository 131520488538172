// General colors
$primary-text-color: #000;
$primary-color: #2e4ce6;
$secondary-color: #7af3d4;
$strawberry: #EB597B;
$ultralight-grey: #e3e5e6;
$grey: #828d99;
$error: #f00;

// Specific colors
$app-bg: #f6fafa;
$common-txt-color: #566973;