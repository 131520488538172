@import '../../../theme.scss';

@mixin color-scheme($color) {
    border: 2px solid $color;
    background-color: $color;
    transition-property: border-color, background-color;
    transition-duration: $transition-short;

    &:hover {
        $hoverColor: lighten($color, 5%);
        border-color: $hoverColor;
        background-color: $hoverColor;
    }

    &:active {
        $hoverColor: darken($color, 5%);
        border-color: $hoverColor;
        background-color: $hoverColor;
    }
}

.button {
    cursor: pointer;
    outline: none;
    padding: $spacing-small;
    margin: $spacing-small;
    border-radius: 3px;
    font-weight: bold;
    font-size: $size-medium;
    color: rgba(0, 0, 0, 0.6);
    @include color-scheme($secondary-color);
}

.fullWidth {
    width: 100%;
    margin: $spacing-small 0;
}

.primary {
    @include color-scheme($primary-color);
    color: #FFF;
}

.muted {
    @include color-scheme($ultralight-grey);
    color: $primary-text-color;
}

.disabled,
.disabled:hover,
.disabled:active {
    border-color: $grey;
    color: $grey;
    background-color: transparent;
    cursor: not-allowed;
}

.linkAsButton {
    text-decoration: none;
    text-align: center;
}