@font-face {
    font-family: 'BasierCircle';
    src: url('./assets/fonts/BasierCircle/basiercircle-regular-webfont.eot');
    src: url('./assets/fonts/BasierCircle/basiercircle-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('./assets/fonts/BasierCircle/basiercircle-regular-webfont.woff2') format('woff2'),
         url('./assets/fonts/BasierCircle/basiercircle-regular-webfont.woff') format('woff'),
         url('./assets/fonts/BasierCircle/basiercircle-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}