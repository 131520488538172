@import '../../theme';

.submitError {
  margin-top: $spacing-small;
  color: $strawberry;
  text-align: center;
}

.loading {
  text-align: center;
}