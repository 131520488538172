@import '../../theme.scss';

.root {
    @include screen-root();
    @include flex-column();
}

.mainRow {
    @include flex-row();
    @include mobile-only() {
        @include flex-column();
    }
    flex: 1;
    justify-content: center;
    align-items: center;
}

.svg, .form {
    @include flex-column();
    flex: 1;
    align-items: center;
    justify-content: center;
    max-width: 425px;
    margin: $spacing-small;
}

.bottomRow {
    @include flex-column();
    flex: 0 0;
    text-align: center;
    img {
        flex: 0 0 5vh;
        min-height: 30px;
    }
}

.submitError {
    margin-top: $spacing-small;
    color: $strawberry;
    text-align: center;
}