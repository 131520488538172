@import '../../../theme.scss';

$z-index-modal-cover: 1;
$widget-border-width: 0;
$widget-border-radius: 5px;
$widget-border-color: $primary-color;
$background-color-default: $app-bg;

$header-content-distance: $spacing-medium;

.cover {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $z-index-modal-cover;
    transform: translateZ(0);
    background: rgba(#000, .7);
    backdrop-filter: blur(3px);
}

.modal {
    position: fixed;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    border: $widget-border-width $widget-border-color solid;
    border-radius: $widget-border-radius;
    background-color: $app-bg;
}

.close {
    border: 0;
    padding: 0;
    margin: $spacing-medium;
    align-self: start;
    cursor: pointer;
    background: transparent;
    color: $primary-text-color;
}

.scrolling {
    overflow: hidden;
}

.header {
    min-height: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $app-bg;
}

.title {
    padding: $spacing-medium;
}

.heading {
    color: $primary-text-color;
}

:global(.mouse-mode) .title:focus {
    outline: none;
}

.content {
    flex-grow: 1;
    padding: $spacing-small;
    overflow-y: auto;
    overflow-x: hidden;
}

.actions {
    height: 10%;
    padding: $spacing-medium 0 0;
    text-align: right;
}
