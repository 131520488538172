@import '../../theme.scss';

$card-margin: 15px;
$card-padding: 25px;

$column-min-width: 250px;
$column-max-width: 500px;

.root {
    display: grid;
    grid-gap: $card-margin;
    position: absolute;
    top: $card-margin;
    bottom: $card-margin;
    left: 0;
    right: 0;
    color: $primary-text-color;
    grid-template-rows: .1fr 1fr 1fr .1fr;
    grid-template-columns: minmax(150px, 1fr) 1.5fr 1.5fr 1fr;
    grid-template-areas:
            "patient summary summary icpc"
            "patient summary summary free"
            "patient location ai free"
            "patient location ai nav";

    aside {
        display: contents;
    }

    .verify,
    .patientData {
        padding: $spacing-medium;
        background: white;
        box-shadow: 1px 0px 2px rgba(0, 0, 0, .3);
    }

    .verify {
        grid-area: ssn;
    }

    .patientData {
        grid-area: patient;
    }

    .summary {
        grid-area: summary;
        .summaryRightCol {
            display: flex;
            flex-direction: column;
            margin-left: $spacing-small;
        }
    }

    .location {
        grid-area: location;
        display: flex;
        flex-direction: column;

        .dummyPositioner {
            position: relative;
            flex-grow: 1;
            overflow: hidden;

            .dummyWrapper {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }
    }

    .ai {
        grid-area: ai;
    }

    .icpc,
    .free,
    .nav {
        padding: $spacing-medium $spacing-medium $spacing-medium 0;
    }

    .icpc {
        grid-area: icpc;
    }

    .free {
        grid-area: free;

        .freeText,
        .freeTextInput,
        .freeTextLabel,
        .freeTextCard {
            display: block;
            border: 0;
            width: 100%;
            height: 100%;
        }

        .freeText textarea {
            font-size: 1rem;
        }
    }

    .nav {
        grid-area: nav;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 0;
    }
}

.card {
    padding: $card-padding;
}

.vSplit {
    display: flex;
    & > * {
        flex: 1;
    }
}

.spacer {
    flex: 1;
}

.valueDisplay {
    & + .valueDisplay {
        margin-top: $spacing-small;
    }
}

.sectionTitle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.2em;
    font-weight: bold;
    color: $primary-color;
    margin-bottom: $spacing-small;
}

.consultFormRoot,
.consultForm {
    display: contents;
}

.buttonStack {
    display: flex;
    flex-direction: column;
}

.fullText {
    position: absolute;
    opacity: 0;
}