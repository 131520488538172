@import '../../common';

.root {
    @include inputDefaults();

    & > * {
        vertical-align: top;
    }

    .textFieldWrapper {
        position: relative;
    }

    .textField {
        margin: 0;
    }

    &.resultsVisible .searchCard {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-color: transparent;
    }
}

.results {
    animation: showResults 2*$transition-short;
    position: absolute;
    z-index: 1;
    width: 100%;
    background: #FFF;
    top: 100%;
    box-shadow: 0 2px 2px rgba(0,0,0,.3);
    border: 1px solid $primary-color;
    border-top-width: 0;
    border-radius: 0 0 5px 5px;
    max-height: 50vh;
    overflow: auto;

    .noResult {
        padding: $spacing-small;
        cursor: not-allowed;
        font-style: italic;
    }

    .result {
        padding: $spacing-small;
        cursor: pointer;
        &:hover, &.highlighted {
            background: rgba($primary-color, .15);
        }
    }
}

@keyframes showResults {
    from {
        max-height: 0;
        opacity: 0;
    }

    to {
        max-height: 100vh;
        opacity: 1;
    }
}