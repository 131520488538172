@import '../../common';

$input-distance: 20px;

$total-size: 30px;
$center-size: 10px;
$border-width-unselected: 3px;
$border-width-selected: ($total-size - $center-size) / 2;

$border-color-unselected: #c9ccd4;
$border-color-selected: $primary-color;


.root {
  display: inline-flex;
  align-items: center;
  padding-left: $input-distance;
  cursor: pointer;
}

.inputWrapper {
  position: relative;
  width: $total-size;
  height: $total-size;
  margin-right: $input-distance;

  .actualInput {
    display: none;
  }

  .styledInput {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    box-sizing: content-box;

    background: transparent;
    border: $border-width-unselected solid $border-color-unselected;
    width: $total-size - 2 * $border-width-unselected;
    height: $total-size - 2 * $border-width-unselected;

    transition: all 150ms ease;
  }

  .actualInput:checked + .styledInput {
    background: white;
    border: $border-width-selected solid $border-color-selected;
    width: $total-size - 2 * $border-width-selected;
    height: $total-size - 2 * $border-width-selected;
  }
}
