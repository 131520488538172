.card {
    width: 100%;
    margin: 50px 0;
    padding: 0;

    .list {
      width: 100%;
      // table-layout: fixed;

      .listHead {
        overflow: auto;
      }

      .listBody {
        overflow: auto;
      }

      .placeholderCell {
        height: 100px;
      }
    }

    .row:hover {
      background: #f0f0f0;
    }
    .selectedRow {
      background: #f8f8f8;
    }
    .disabledRow {
      opacity: .6;
    }

    .searchCell {
      padding: 0;
    }
    .searchInput {
      width: 100%;
      font-size: 1.5em;
      padding: 8px;
    }
  }