@import '../../common';

.dropDown {
    @include inputDefaults();
    .dropDownWrapper {
        position: relative;
        .box {
            display: flex;
            width: 100%;
            resize: vertical;
            font-size: 1rem;
            border: 1px solid $grey;
            border-radius: 3px;
            background: none;
            outline: none;
            padding: $spacing-small/2 $spacing-small;
            padding-right: 0;
            // mimic native inputs default size
            min-width: 192px;
            cursor: pointer;
            .valueDisplay {
                flex: 1 1 0;
            }
            .icon {
                flex: 0 0 auto;
                transform: rotate(0deg);
                transition: transform $transition-short ease-in;
            }
        }
        $transition-time: 150ms;
        .dropPanel {
            position: absolute;
            z-index: 100;
            top: 100%;
            left: 0;
            width: 100%;
            background: #FFF;
            box-shadow: 0 1px 3px rgba(0,0,0,.3);
            max-height: 0;
            overflow: auto;
            max-height: 0px;
            transition-property: transform, opacity, max-height;
            transition-duration: $transition-short;
            transition-timing-function: ease-in;
            transform-origin: 50% 0;
            opacity: 0.4;
            transform: scale(.8);
            .option {
                cursor: pointer;
                padding: $spacing-small/2 $spacing-small;
                white-space: nowrap;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                &.selected {
                    color: $primary-color;
                }
                &:hover {
                    background: $ultralight-grey;
                }
            }
        }
    }
    &.open .dropDownWrapper {
        .icon {
            transform: rotate(180deg);
            transition-timing-function: ease-out;
        }
        .dropPanel {
            transform: scale(1);
            opacity: 1;
            max-height: 200px;
            transition-timing-function: ease-out;
        }
    }
}