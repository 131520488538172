
.patientId {
    text-transform: uppercase;
}
  

.summaryList {
    width: 100%;
    flex: 1;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

