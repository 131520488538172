@import '../../common';

.textField {
    @include inputDefaults();

    // default size needed
    width: 200px;

    &:focus-within {
        .label {
            color: $primary-color;
        }
        .inputWrapper {
            border-color: $primary-color;
        }
        &.withClearSign {
            input {
                width: calc(100% - #{25px + $spacing-small});
            }
    
            .clearSign {
                visibility: visible;
                opacity: 1;
                animation: $anim-fadeIn $transition-short;
            }
        }
    }

    .label {
        transition: color $transition-short;
    }

    .inputWrapper {
        transition: border-color $transition-short;
        display: inline-block;
        position: relative;
        width: 100%;
        border: 1px solid transparent;
    }

    input, textarea {
        resize: none;
        font-size: 1rem;
        border: none;
        background: none;
        width: 100%;
        color: $common-txt-color;
        padding: 0;
    }

    .clearSign {
        visibility: hidden;
        display: inline;
        position: absolute;
        right: $spacing-small + 1;
        top: calc(50% - 2px);
        transform: translateY(-50%);
        font-size: 25px;
        line-height: .8em;
        color: $primary-color;
        cursor: pointer;
        opacity: 0;
        animation: $anim-fadeOut $transition-short;
    }
}
