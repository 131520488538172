@import '../../theme.scss';

$max-width: 1000px;
$body-height-base: 90vh;
$body-height-page-offset: 350px;
$body-min-height: 250px;

.root {
  padding: $spacing-small;
  max-width: $max-width;
  width: 100%;
  margin: 0 auto 10px;
}

.verifyModal,
.viewModeModal {
  width: 300px;
}

.viewModeModalRoot {
  display: flex;
  justify-content: space-between;

  & > * {
    margin: $spacing-small;
  }
}
.spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.button:last-of-type {
    margin-right: 0;
}
