@import '../../common';

.root {
    @include inputDefaults();
    display: flex;
    flex-direction: column;
}

.optionsContainer {
    display: flex;
}

.option {
    @include inline-spacing();
    margin-bottom: 0;
    cursor: pointer;
    flex: 1;

    input {
        display: none;
    }

    input:checked + .card {
        color: $primary-color;
    }

    .card {
        display: flex;
        align-items: center;
        // same size as other inputs
        border: 1px solid transparent;
    }
}

.hasValue input:not(:checked) + .card {
    opacity: .5;
}