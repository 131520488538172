@import '../../../theme.scss';

.root {
    display: flex;
    flex-direction: column;
    flex: 1;

    header {
        position: fixed;
        width: 100%;
        border-bottom: 1px solid $grey;
        background-color: $app-bg;
        z-index: 1;
    }

    main {
        position: relative;
        display: flex;
        flex: 1;
        align-items: flex-start;
        justify-content: flex-start;
    }
}

.banner {
    $padding: 2*$spacing-small;
    display: flex;
    justify-content: space-between;
    background: $primary-color;
    color: #FFF;
    flex: 1;
    font-size: 1.5em;
    padding: 0 $spacing-small;

    & > * {
        height: 100%;
    }

    .logoWrapper {
        height: 100%;
        padding: $padding $spacing-small;
    }
    .logo {
        min-height: 100%;
        display: block;
        width: auto;
    }
    
    .buttonsRow {
        display: flex;
        flex-wrap: nowrap;
        height: 100%;
    }
    
    .iconBtn {
        transition: background-color $transition-short;
        border: none;
        color: #FFF;
        background-color: transparent;
        padding: $padding $spacing-small;
        display: inline-flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        height: 100%;
        font-size: inherit;

        &:hover {
            background-color: rgba(#FFF,.1);
        }
        &:active {
            background-color: rgba(#FFF,.15);
        }
    
        svg {
            height: 100%;
        }
    }
}

.menu {
    flex: 0 0 auto;
}