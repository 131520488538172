@import './font-faces.scss';
@import './theme.scss';

body {
  margin: 0;
  font-family: BasierCircle, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $app-bg;
  color: $common-txt-color;
}

* {
  box-sizing: border-box;
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  margin: 0 0 $spacing-small;
  color: #000;
}

::placeholder {
  color: lighten($common-txt-color, 20%);
}
